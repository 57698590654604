import 'firebase/auth';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { inject, observer } from 'mobx-react';
import UserAPI from '../api/user.api';
import LogoFork from '../assets/logo-fork.svg';
import Logo from '../assets/logo.svg';
import Button from '../components/Button';
import { IRootStore } from '../stores/RootStore';
import theme from '../styles/theme';
import {
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { FirebaseAuthSession } from '../index';
const LoginPageStyle = styled.div`
  position: relative;
  height: 100vh;
  background-color: ${theme.colors.dark1};
  color: #fff;

  .logo {
    margin: 8px;
  }

  .logo-fork {
    display: block;
    margin: 0 auto;
    margin-top: 40px;
  }

  .form-wrapper {
    display: flex;
    margin-top: 40px;

    & > div {
      max-width: 500px;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: flex;
      margin: 0 auto;
    }

    input {
      display: block;
      width: 304px;
      height: 40px;
      margin-bottom: 8px;
      padding: 8px;
      border: 0px;
      font-size: 12px;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
      }
      ::placeholder {
        color: ${theme.colors.dark2};
      }
    }

    button {
      width: 304px;
    }

    .msg-error {
      width: 304px;
      text-align: center;
      padding: 16px;
      color: ${theme.colors.danger};
    }
  }
`;
//Multifactor auth related
export let recaptchaVerifier: RecaptchaVerifier | null = null;
const setRecaptchaVerifier = () => {
  recaptchaVerifier = new RecaptchaVerifier(
    'recaptcha-container-id',
    {
      size: 'invisible',
      callback: (response: any) => {
        // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
        console.log('solved baby');
      },
    },
    FirebaseAuthSession,
  );
};
type State = {
  username: string;
  password: string;
  phoneNumber: string;
  verifyCode: string;
  verificationId: string;
  redirect: boolean;
  isLoading: boolean;
  errorMessage: string;
  resolver: any;
  mfa: boolean;
  mfaPage: string;
};

type Props = {
  store?: IRootStore;
};

//Multifactor auth related
//export let recaptchaVerifier: RecaptchaVerifier | null = null;

@inject('store')
@observer
class Login extends Component<Props, State> {
  api: UserAPI;

  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      phoneNumber: '',
      verifyCode: '',
      verificationId: '',
      redirect: false,
      isLoading: false,
      errorMessage: '',
      resolver: null,
      mfa: false,
      mfaPage: 'phone',
    };

    this.api = new UserAPI();
  }

  componentDidMount() {
    this.props.store!.RestaurantStore.setUser(null);
    // setRecaptchaVerifier();
  }

  submitLogin() {
    this.setState({ isLoading: true, errorMessage: '' });
    /* if (recaptchaVerifier === null) {
      setRecaptchaVerifier();
    }*/

    signInWithEmailAndPassword(FirebaseAuthSession, this.state.username, this.state.password)
      .then((userCredential) => {
        // User successfully signed in and is not enrolled with a second factor.
        this.setState({ redirect: true });
      })
      .catch(async (error) => {
        if (error.code == 'auth/multi-factor-auth-required') {
          // The user is a multi-factor user. Second factor challenge is required.

          const ssResolver = getMultiFactorResolver(FirebaseAuthSession, error);

          this.setState({ resolver: ssResolver });

          if (!recaptchaVerifier) {
            setRecaptchaVerifier();
          }

          if (recaptchaVerifier) {
            const phoneAuthProvider = new PhoneAuthProvider(FirebaseAuthSession);
            const phoneInfoOptions: PhoneInfoOptions = {
              multiFactorHint: ssResolver.hints[0],
              session: ssResolver.session,
            };

            const verificationId = await phoneAuthProvider
              .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
              .then((s) => {
                console.log(s, 's');
                return s;
              })
              .catch(function (error) {
                alert(`Error verifying phone number. ${error}`);
                throw error;
              });

            this.setState({ verificationId, isLoading: false, mfa: true, mfaPage: 'code' });
          }
          console.log(error, 'error');

        } else if (error.code == 'auth/wrong-password') {
          // Handle other errors such as wrong password.
          this.setState({ isLoading: false, errorMessage: error.message });
        }
      });
  }

  handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      this.submitLogin();
    }
  }

  render() {
    const { redirect, isLoading, errorMessage } = this.state;
    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <LoginPageStyle>
        <object className="logo" data={Logo} type="image/svg+xml" aria-label="Dineout logo" />
        <object
          className="logo-fork"
          data={LogoFork}
          type="image/svg+xml"
          aria-label="Dineout fork"
        />
        <div className="form-wrapper">
          {!this.state.mfa ? (
            <div>
              <p>Please log in.</p>
              <input
                type="text"
                value={this.state.username}
                name="username"
                placeholder="Username"
                onChange={(e) => {
                  const { value } = e.target;
                  this.setState({
                    username: value,
                  });
                }}
                onKeyPress={(e) => this.handleKeyPress(e)}
              />
              <input
                type="password"
                value={this.state.password}
                name="password"
                placeholder="Password"
                onChange={(e) => {
                  const { value } = e.target;
                  this.setState({
                    password: value,
                  });
                }}
                onKeyPress={(e) => this.handleKeyPress(e)}
              />
              <Button onClick={() => this.submitLogin()} isLoading={isLoading} disabled={isLoading}>
                Login
              </Button>
              {errorMessage ? <div className="msg-error">{errorMessage}</div> : null}
            </div>
          ) : this.state.mfaPage == 'phone' ? (
            <div>
              <p> MFA enter your phone number</p>

              <input
                type="text"
                value={this.state.phoneNumber}
                name="phoneNumber"
                placeholder="Phone number"
                onChange={(e) => {
                  const { value } = e.target;
                  this.setState({
                    phoneNumber: value,
                  });
                }}
                onKeyPress={(e) => this.handleKeyPress(e)}
              />
              <Button
                onClick={() => {
                  const phoneAuthProvider = new PhoneAuthProvider(FirebaseAuthSession);

                  if (recaptchaVerifier === null) {
                    setRecaptchaVerifier();
                  }

                  this.setState({ isLoading: true });
                  if (recaptchaVerifier != null) {
                    console.log('herna');
                    phoneAuthProvider
                      .verifyPhoneNumber({ phoneNumber: this.state.phoneNumber }, recaptchaVerifier)
                      .then((s) => {
                        console.log('verifyNumber');
                        this.setState({ verificationId: s, isLoading: false, mfaPage: 'code' });
                      })
                      .catch((s) => {
                        console.log(s, 's');
                      });
                    console.log('confirm phone');
                  }
                }}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Confirm phone
              </Button>
            </div>
          ) : (
            <div>
              <p> MFA code</p>

              <input
                type="text"
                value={this.state.verifyCode}
                name="verifyCode"
                placeholder="Verify code"
                onChange={(e) => {
                  const { value } = e.target;
                  this.setState({
                    verifyCode: value,
                  });
                }}
                onKeyPress={(e) => this.handleKeyPress(e)}
              />
              <Button
                onClick={() => {
                  this.setState({ isLoading: true });

                  const cred = PhoneAuthProvider.credential(
                    this.state.verificationId,
                    this.state.verifyCode,
                  );

                  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

                  // Complete sign-in.
                  this.state.resolver
                    .resolveSignIn(multiFactorAssertion)
                    .then((userCredential: any) => {
                      // User successfully signed in with the second factor phone number.
                      console.log(userCredential, 'userCredential');
                      this.setState({ redirect: true });
                    });
                }}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Verify code
              </Button>
            </div>
          )}
          <div style={{ visibility: 'hidden' }} id="recaptcha-container-id" />
        </div>
      </LoginPageStyle>
    );
  }
}

export default Login;
