import { inject, observer } from 'mobx-react';
import * as Moment from 'moment-timezone';
import moment from 'moment-timezone';
import queryString from 'query-string';
import React, { Component } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import LogoFork from '../assets/logo-fork.svg';
import { IRootStore } from '../stores/RootStore';
import { ModalConductor } from '../stores/UIStore';
import { UserRole } from '../stores/UserStore';
import theme from '../styles/theme';
import Utils from '../utils';
import LoadingSpinner from './common/LoadingSpinner';

const Nav = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  z-index: 10;
  background-color: #1c1c1c;
  color: #fff;

  a {
    color: #fff;
    font-size: 14px;
    &.active {
      font-weight: 500;
    }
  }
  > * {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .logo-container {
    width: 72px;
    .logo-fork {
      transform: scale(0.26);
      transform-origin: center center;
    }
  }

  .navbar-item {
    padding: 0px 9px;
    &.btn-home {
      padding-left: 0px;
    }
  }

  .btn-quick-book {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 16px 0px 16px;
    margin-left: 16px;
    background-color: ${theme.colors.success};

    i {
      color: #fff;
    }
  }

  .btn-walk-in {
    padding: 0px 16px 0px 16px;
    &:hover {
      > i {
        color: ${theme.colors.success};
      }
    }
  }

  .loading {
    width: 48px;
    > div {
      margin-left: 10px;
      margin-bottom: 22px;
    }
  }

  .logout {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    a {
      display: flex;
      align-items: center;
    }
    i {
      display: block;
      font-size: 16px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1024px) {
    > * {
      display: none;
    }
    .logo-container,
    .btn-quick-book,
    .btn-walk-in {
      display: flex;
      width: 64px;
    }
    .logout {
      span:first-child {
        display: none;
      }
    }
  }

  @media print {
    display: none;
  }
`;

interface IProps extends RouteComponentProps<any> {
  store?: IRootStore;
}

type State = {
  isNavOpen: boolean;
};

@inject('store')
@observer
class Navbar extends Component<IProps, State> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isNavOpen: false,
    };
  }

  menuItemClick() {
    this.setState({
      isNavOpen: false,
    });
  }

  goToDashboard() {
    this.props.history.push('/');
  }

  getUrlParams(search: string): {
    date: Moment.Moment;
    viewMode: string | null;
  } {
    let selectedDate = new Utils().roundUpQuarter(moment());
    let viewMode = null;

    const query = queryString.parse(search);
    if (query) {
      if (Object.getOwnPropertyDescriptor(query, 'date')) {
        selectedDate = moment(query.date, 'YYYY-MM-DD');
      }
      if (Object.getOwnPropertyDescriptor(query, 'viewMode')) {
        viewMode = query.viewMode as string;
      }
    }

    return {
      date: selectedDate,
      viewMode,
    };
  }

  onBookingSave(selectedDate: Moment.Moment, newDate: Moment.Moment) {
    const { viewMode } = this.getUrlParams(this.props.history.location.search);
    const path = {
      pathname: '/',
      search: `?date=${newDate.format('YYYY-MM-DD')}${
        viewMode && '&viewMode=' + viewMode
      }&refetchData=true`,
    };

    if (selectedDate.isSame(newDate, 'date')) {
      this.props.history.replace(path); // Replace instead of push to prevent unwanted history
    } else {
      this.props.history.push(path);
    }
  }

  render() {
    const { email, restaurantId, proxyRole } = this.props.store!.RestaurantStore.user!;

    const isAdmin = proxyRole === UserRole.Admin;
    const isManager = proxyRole === UserRole.Manager;
    const bookingsDisabled = this.props.store!.RestaurantStore.restaurant?.id === 235;

    return (
      <Nav>
        <div className="logo-container">
          <NavLink
            activeClassName="active-ignore"
            className="logo-fork"
            to={!isAdmin ? '/' : '/restaurant'}
            exact
            onClick={() => this.goToDashboard()}
          >
            <object data={LogoFork} type="image/svg+xml" aria-label="Dineout" />
          </NavLink>
        </div>
        {isAdmin && (
          <NavLink
            activeClassName="active"
            to="/restaurant"
            className="navbar-item btn-home"
            exact
            onClick={() => this.menuItemClick()}
          >
            Home
          </NavLink>
        )}

        {isAdmin && (
          <NavLink
            activeClassName="active"
            to="/place"
            className="navbar-item"
            exact
            onClick={() => this.menuItemClick()}
          >
            Places
          </NavLink>
        )}

        {isAdmin && (
          <NavLink
            activeClassName="active"
            to="/billing"
            className="navbar-item"
            exact
            onClick={() => this.menuItemClick()}
          >
            Billing
          </NavLink>
        )}

        {isAdmin && (
          <NavLink
            activeClassName="active"
            to="/activity"
            className="navbar-item"
            exact
            onClick={() => this.menuItemClick()}
          >
            Activity
          </NavLink>
        )}

        {isAdmin && (
          <NavLink
            activeClassName="active"
            to="/admanagement"
            className="navbar-item"
            exact
            onClick={() => this.menuItemClick()}
          >
            Ad Management
          </NavLink>
        )}

        {isAdmin && (
          <NavLink
            activeClassName="active"
            to="/translate/categories"
            className="navbar-item"
            exact
            onClick={() => this.menuItemClick()}
          >
            Categories
          </NavLink>
        )}

        {!isAdmin && (
          <NavLink
            activeClassName="active"
            to="/"
            className="navbar-item btn-home"
            exact
            onClick={() => this.goToDashboard()}
          >
            Dashboard
          </NavLink>
        )}

        {!isAdmin && (
          <NavLink
            activeClassName="active"
            to="/bookings"
            className="navbar-item"
            onClick={() => this.menuItemClick()}
          >
            Bookings
          </NavLink>
        )}

        {!isAdmin && (
          <NavLink
            activeClassName="active"
            to="/waitinglist"
            className="navbar-item"
            onClick={() => this.menuItemClick()}
          >
            Waiting List
          </NavLink>
        )}

        {!isAdmin && !isManager && (
          <NavLink
            activeClassName="active"
            to="/surveys"
            className="navbar-item"
            onClick={() => this.menuItemClick()}
          >
            Surveys
          </NavLink>
        )}

        {isManager && restaurantId && (
          <NavLink
            activeClassName="active"
            to={'/restaurant/edit'}
            className="navbar-item"
            onClick={() => this.menuItemClick()}
          >
            Restaurant
          </NavLink>
        )}

        {isManager && restaurantId && (
          <NavLink
            activeClassName="active"
            to={'/floorplan'}
            className="navbar-item"
            onClick={() => this.menuItemClick()}
          >
            Rooms
          </NavLink>
        )}

        {isManager && (
          <NavLink
            activeClassName="active"
            to="/customer"
            className="navbar-item"
            onClick={() => this.menuItemClick()}
          >
            Customers
          </NavLink>
        )}

        {isManager && (
          <NavLink
            activeClassName="active"
            to={'/rule'}
            className="navbar-item"
            onClick={() => this.menuItemClick()}
          >
            Rules
          </NavLink>
        )}

        {isManager && (
          <NavLink
            activeClassName="active"
            to={`/menu`}
            className="navbar-item"
            onClick={() => this.menuItemClick()}
          >
            Menus
          </NavLink>
        )}
        {isManager && (
          <NavLink
            activeClassName="active"
            to={`/archive`}
            className="navbar-item"
            onClick={() => this.menuItemClick()}
          >
            Archive
          </NavLink>
        )}

        {isManager && (
          <NavLink
            activeClassName="active"
            to={`/translate`}
            className="navbar-item"
            onClick={() => this.menuItemClick()}
          >
            Translate
          </NavLink>
        )}

        {!isAdmin && !bookingsDisabled && (
          <a
            className="btn-quick-book"
            title="New Booking"
            onClick={() => {
              this.menuItemClick();

              const selectedDate = this.getUrlParams(this.props.history.location.search).date;
              this.props.store!.UIStore.openModal({
                type: ModalConductor.Type.Booking,
                date: selectedDate,
                onSave: (newDate: Moment.Moment) => {
                  this.onBookingSave(selectedDate, newDate);
                },
              });
            }}
            href="#"
          >
            <i className="material-icons">&#xE145;</i>
          </a>
        )}

        {!isAdmin && !bookingsDisabled && (
          <a
            className="btn-walk-in"
            title="New Booking"
            onClick={() => {
              this.menuItemClick();

              const selectedDate = moment();
              this.props.store!.UIStore.openModal({
                type: ModalConductor.Type.Booking,
                isWalkIn: true,
                date: selectedDate,
                onSave: (newDate: Moment.Moment) => {
                  this.onBookingSave(selectedDate, newDate);
                },
              });
            }}
            href="#"
          >
            <i className="material-icons">directions_walk</i>
          </a>
        )}
        <div className="loading">{this.props.store!.UIStore.isLoading && <LoadingSpinner />}</div>
        <div className="logout">
          <NavLink
            activeClassName="active-ignore"
            to="/logout"
            onClick={() => this.menuItemClick()}
          >
            <span>{email}</span>
            <span>
              <i className="material-icons">exit_to_app</i>
            </span>
          </NavLink>
        </div>
      </Nav>
    );
  }
}

export default withRouter(Navbar);
